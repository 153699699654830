// Profile.js
import React from 'react';
// import './Profile.css';

function Profile() {
    return (
        <section id="profile">
            <div className="section__pic-container">
                <img alt="Johnpicture" src="./images/profile-pic.jpg" />
            </div>
            <div className="section__text">
                <p className="section__text__p1">Hello, I'm</p>
                <h1 className="title">John <br />JEAN BAPTISTE</h1>
                <p className="section__text__p2">Full Stack Developer</p>
                <div className="btn-container">
                    <button className="btn btn-color-2" onClick={() => window.open('./JOHN_JEANBAPTISTE-Resume.pdf')}>
                        Download CV
                    </button>
                    <button className="btn btn-color-1" onClick={() => window.location.href = './#contact'}>
                        Contact Info
                    </button>
                </div>
                <div id="socials-container">
                    <img src="./images/linkedin.png" alt="My LinkedIn profile" className="icon" onClick={() => window.location.href = 'https://www.linkedin.com/in/johnwesley-jeanbaptiste/'} />
                    <img src="./images/github.png" alt="My Github profile" className="icon" onClick={() => window.location.href = 'https://github.com/johnwesley-jb/'} />
                </div>
            </div>
        </section>
    );
}

export default Profile;
