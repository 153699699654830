// Footer.js
import React from 'react';
// import './Footer.css';
// const Rights = process.env.MY_NAME;

function Footer() {
    return (
        <footer>
            <nav>
                <div className="nav-links-container">
                    <ul className="nav-links">
                        <li><a href="#about">About</a></li>
                        <li><a href="#experience">Experience</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#blog">Blog</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
            </nav>
            <p>Copyright &#169; 2024 John JEAN BAPTISTE. All Rights Reserved.</p>
            {/* <p>{Rights}</p> */}
        </footer>
    );
}

export default Footer;
