// Projects.js
import React from 'react';
// import './Projects.css';

function Projects() {
    return (
        <section id="projects">
            <p className="section__text__p1">Browse My Recent</p>
            <h1 className="title">Projects</h1>
            <div className="experience-details-container">
                <div className="about-containers">
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img src="./images/project-1.png" alt="Project 1" className="project-img" />
                        </div>
                        <h2 className="experience-sub-title project-title">Join Learning</h2>
                        <div className="btn-container">
                            <button className="btn btn-color-2 project-btn" onClick={() => window.location.href = 'https://github.com/'}>
                                Github
                            </button>
                            <button className="btn btn-color-2 project-btn" onClick={() => window.location.href = 'https://github.com/'}>
                                Live Demo
                            </button>
                        </div>
                    </div>

                    <div className="details-container color-container">
                        <div className="article-container">
                            <img src="./images/project-3.png" alt="Project 3" className="project-img" />
                        </div>
                        <h2 className="experience-sub-title project-title">Personal Hobby</h2>
                        <div className="btn-container">
                            <button className="btn btn-color-2 project-btn" onClick={() => window.location.href = 'https://github.com/'}>
                                Github
                            </button>
                            <button className="btn btn-color-2 project-btn" onClick={() => window.location.href = 'https://github.com/'}>
                                Live Demo
                            </button>
                        </div>
                    </div>
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img src="./images/project-2.png" alt="Project 2" className="project-img" />
                        </div>
                        <h2 className="experience-sub-title project-title">Real Estate App</h2>
                        <div className="btn-container">
                            <button className="btn btn-color-2 project-btn" onClick={() => window.location.href = 'https://github.com/'}>
                                Github
                            </button>
                            <button className="btn btn-color-2 project-btn" onClick={() => window.location.href = 'https://github.com/'}>
                                Live Demo
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>
            <img src="./images/arrow.png" alt="Arrow icon" className="icon arrow" onClick={() => window.location.href = './#blog'} />
        </section>
    );
}

export default Projects;
