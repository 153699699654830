// About.js
import React from 'react';
// import './About.css';

function About() {
    return (
        <section id="about">
            <p className="section__text__p1">Get To Know More</p>
            <h1 className="title">About Me</h1>
            <div className="section-container">
                <div className="section__pic-container">
                    <img src="./images/about-pic.jpg" alt="Profile picture" className="about-pic" />
                </div>
                <div className="about-details-container">
                    <div className="about-containers">
                        <div className="details-container">
                            <img src="./images/experience.png" alt="Experience icon" className="icon" />
                            <h3>Experience</h3>
                            <p>4+ years <br />Software Development</p>
                        </div>
                        <div className="details-container">
                            <img src="./images/education.png" alt="Education icon" className="icon" />
                            <h3>Education</h3>
                            <p>B.Sc. Bachelors Degree<br />M.Sc. Masters Degree</p>
                        </div>
                    </div>
                    <div className="text-container">
                        <p>
                            Dynamic and results-oriented Full Stack Developer with a proven track record of designing and implementing
                            robust web
                            applications. Proficient in both Backend and Frontend technologies, including Java, Node.js, Angular, and
                            React.
                            Demonstrated ability to architect and deploy sophisticated backend systems, web services, and databases to
                            meet diverse
                            business needs.
                        </p>
                    </div>
                </div>
            </div>
            <img src="./images/arrow.png" alt="Arrow icon" className="icon arrow" onClick={() => window.location.href = './#experience'} />
        </section>
    );
}

export default About;
