import React, { useState } from 'react';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: ''
    });
    const [responseMessage, setResponseMessage] = useState(null);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://hd732wkwbd.execute-api.us-east-1.amazonaws.com/v1/prod', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (response.ok) {
                setResponseMessage(result);
            } else {
                setResponseMessage(result);
            }
        } catch (error) {
            setResponseMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <section id="contact">
            {/* <p className="section__text__p1">Get In Touch</p> */}
            <h1 className="title">Contact Me</h1>
            <div className="contact-container">
                <div className="contact-info">
                    <h3>Get in touch</h3>
                    <p>Fill out the contact form to start a conversation with me. I'm open to freelance web dev work, collaborating in open-source, or assisting beginners with information about web development.</p>
                    <p><img src='./images/location.png' alt="Location icon" className="icon" /> Massachusets, US</p>
                    <p><img src='./images/email.png' alt="Email icon" className="icon" /> johnwesley.jnbaptiste@gmail.com</p>
                </div>
                <div className="contact-form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone:</label>
                            <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">Subject:</label>
                            <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
                        </div>
                        <button type="submit" className="btn btn-color-2">Submit</button>
                    </form>
                    {responseMessage && <p>{responseMessage}</p>}
                </div>
            </div>
        </section>
    );
}

export default Contact;
