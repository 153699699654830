// Blog.js
import React from 'react';

function Blog() {
    return (
        <section id="blog">
            <p className="section__text__p1">Latest Posts</p>
            <h1 className="title">Blog</h1>
            <div className="blog-container">
                <div className="blog-post">
                    <h2 className="blog-title">Serverless Architecture</h2>
                    <p className="blog-date">May 25, 2024</p>
                    <p className="blog-excerpt">
                        In this article, we will explore a serverless architecture designed for handling contact form submissions, sending notifications, and storing data in a database. The architecture leverages various AWS services, including API Gateway, Lambda, SNS, DynamoDB, SQS, and CloudWatch.
                    </p>
                    <button className="btn btn-color-2" onClick={() => window.open('https://medium.com/@wesleyjb77/serverless-architecture-for-contact-form-submission-and-processing-9d08e743c3c0')}>Read More</button>
                </div>
                <div className="blog-post">
                    <h2 className="blog-title">IOS vs Android</h2>
                    <p className="blog-date">May 25, 2024</p>
                    <p className="blog-excerpt">
                        When it comes to choosing a mobile operating system, two names dominate the conversation: Apple's iOS and Google's Android. Both have their unique strengths and cater to different user needs. Here’s a quick comparison to help you decide which OS suits you best.
                    </p>
                    <button className="btn btn-color-2" onClick={() => window.open('https://medium.com/@wesleyjb77/ios-vs-android-935b525c8adb')}>Read More</button>
                </div>
                <div className="blog-post">
                    <h2 className="blog-title">IOS vs Android</h2>
                    <p className="blog-date">May 25, 2024</p>
                    <p className="blog-excerpt">
                        When it comes to choosing a mobile operating system, two names dominate the conversation: Apple's iOS and Google's Android. Both have their unique strengths and cater to different user needs. Here’s a quick comparison to help you decide which OS suits you best.
                    </p>
                    <button className="btn btn-color-2" onClick={() => window.open('https://medium.com/@wesleyjb77/ios-vs-android-935b525c8adb')}>Read More</button>
                </div>

            </div>

        </section>
    );
}

export default Blog;
